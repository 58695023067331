.card{ 
    width: 92%;
    height: auto;
    border: none;
    position: fixed;
    border-radius: 0.5rem;
    align-items: center;
    border-left: 10px solid #205d88;
    margin: 1rem auto;
    padding: 1rem;
    top:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    p{
        font-size: 1.4rem;
        text-align: center;
    }
    .Unsubscribe-btn{
        border-radius: 2rem;
        background-color: #205d88;
        color: #ffffff;
        font-weight: 500;
        padding: 15px;
        text-align: center;
        font-size: 1.8rem;
        border: solid 2px #ffffff;
    }
    .home-btn{
        font-size: 1.5rem;
        color:#007bff
    }
}