

.App {
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
  min-height: 100vh;
  background-color:  #9876ff
}
.main {
  background-color:  #9876ff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 100vh;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
}
.arabic-lang{
  margin: 5px;
  direction: rtl;
}


.loader-container {
  background: rgba(0, 0, 0, 0.834);
  position: fixed;
  left: 0px;
  top: 50px;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 2px solid;
  border-color: #ffffff transparent #ffffff transparent;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.Modal {
  position: absolute; 
  top: 0; 
  left: 0;
  z-index: 2050;
  width: 100%;
  height: 100vh;
  background-color:rgba(0,0,0,0.5); 
  display: flex;
  opacity: 1.9; 
  /* //transition: opacity .5s linear; */
  overflow-x: hidden; 
  overflow-y: hidden;
}
.modal_fade{
  transition: transform .3s ease-out;
    transform: translate(0,0);
} 



.home {
  background-image: url("../Images/Home-screen-bg.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

}
.swip-logo {
  display: flex;
  align-items: center;
}

.swip-logo img {
  cursor: pointer;
}
.swipe-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swipe-logo img {
  cursor: pointer;
}
/* Base styles */
.img-banner {
position: absolute;
bottom: 16px;
left: 50%;
transform: translateX(-50%);
max-width: 480px;
width: 92%;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
.img-banner {
  bottom: 8px;
  max-width: 90%;
}
}

.swip-title {
  margin-top: 25.6rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  padding: 3rem 1.5rem 7rem;
  justify-content: flex-end;
  flex-direction: column;
  .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff !important;
  }
  .sub-title {
    color: #fff;
    padding-top: 10px;
    font-weight: 500;
    text-align: center;
    font-size: 1.1rem;
  }
  .btn-play-now {
    font-size: 18px !important;
    font-weight: 700;
    justify-content: flex-start;
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    text-align: left;
    border: transparent;
    min-width: 200px;
    background-color: #215d88;
    color: #fff;
    border-width: 1px;
    cursor: pointer;
    justify-content: center;
    padding: 16px;
    text-align: center;
    white-space: nowrap;
    border-radius: 25px;
    margin-top: 20px;
    width: 100%;
  }

}
header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.fix-w {
  height: 100%;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  margin: 0 auto;
  /* //background: linear-gradient(to bottom, #f9b970, #f54048); */
}



.language {
  background-color: #6c8c4a;
  height: 40px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.dropdown-toggle.menuitem::after {
  display: none;
}

/* .dropdown.cus .dropdown-menu {
  // min-width: 5rem;
  // max-width: 5.5rem;
  // width: 300px;
  // padding: 0;
} */

.dropdown-item {
   /* text-align: center; */
  display: flex ;
  flex-direction: row;
  justify-content: space-between;
  margin:auto;
}

.dropdown-item:hover {
  background-color: pink;
}
.mr-15
{
  margin-right:2rem !important;
}
/* src/Slider.css */
.slider {
display: flex;
align-items: center;
justify-content: center;
}

.slider button {
margin: 0 10px;
padding: 10px;
font-size: 16px;
}

.slider div {
text-align: center;
}



#langWrapper div {
  position: absolute;
  bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.position
{
  position: absolute;
  margin-top: -10rem;
}
.hidden
{
  display: none;
}
.font-xl
{
  font-size: 25px;
}

.algin
{
  margin: auto;
  font-size:.75rem;
  color:black;
}
.btn {
  background-color: #daa09c;
  border-color: #daa09c;
  margin: 10px auto;
  color: black;
  width: 200px;
  &.disabled {
    color: #fff;
     /* background-color: $theme-color; */
  }
}
.language {
  background-color: #6c8c4a;
  height: 40px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
}

.dropdown-toggle.menuitem::after {
  display: none;
}

.dropdown.cus .dropdown-menu {

  width: 200px;
  padding: 0;
}


.dropdown-item:hover{
  background-color: pink;
  cursor: pointer;
}


.flag{
  position: relative;
}

a:hover{
  text-decoration: none;
  outline: none;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #25023b;
  border: 2px solid white;
  border-radius: 3px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #25023b;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container input:focus {
  outline: none; /* no outline - for most browsers */
  box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
}
.container input:checked ~ .checkmark {
  background-color: white;
}

.lang-flag
{
  width:30px;
  height:20px;
  border-radius: 5px;
  margin:auto;
  justify-content: flex-end !important;
}
#flag1, #flag2,#flag3{
  border: 2px solid white;
  border-radius: 50%;
  margin-right:2px;
}

#flag2{
  margin-left: -2px;
}

#langWrapper div{
  position: absolute;
  bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5;
}
.main-btns-div
{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.lng-btn
{
  background-color: #daa09c;
  border-color: #daa09c;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  margin-left:0.5rem;
  margin-right:0.5rem;
}
.overlay.show {
  position: fixed;
  opacity: 1;
}

.title_lang{
  position: absolute;
  margin-top: 28rem;
  left:6rem
}





.swipe_bg{
background-image: url("../Images/inner_bg.png");
background-position: center;
background-size: cover;
background-repeat: no-repeat;
}
.swipe {
  position: fixed;
  display: flex;
  max-width: 480px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 180px);
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: space-between;
  z-index: 11;
  .swip_cards {
    height: 362px;
    width: 270px;
    /* // background-color: #fff; */
    z-index: 22;
    will-change: transform;
    text-align: center;
    position: absolute;
    top: calc(46% - 270px / 2);
    left: calc(56% - 362px / 2);
    z-index: 3;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
    border-radius: 10px;
    p {
      font-size: 1rem;
      color: #ffffff;
      z-index: 11 !important;
      margin-top: 2rem;
      font-weight: 500;
      line-height: 1.2;
    }
    img {
      height: 100%;
    }
  }
}
.btn-RL {
  position: absolute !important;
  top: 650px !important;
  left: 25% !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  .left {
    background-color: #000;
    opacity: 0.3;
    top: 10px;
    padding-top: 6px;
    border-radius: 50px;
  }
  .right {
    background-color: #000;
    opacity: 0.3;
    top: 10px;
    padding-top: 6px;
    border-radius: 50px;
  }
}
.swip_title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  background-color: black;
  opacity: 0.3;
  border-radius: 20px;
  padding: 8px;
  margin-top: 0;
  margin-bottom: 2rem;
}
.counter {
  position: absolute;
  top: 600px;
  left: 42%;
  background-color: #000;
  opacity: 0.3;
  color: #fff;
}
.swipe-cards-background::before {
  content: "";
  width: 270px;
  height: 362px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(6deg) translate(-50%, -50%);
  border-radius: 1rem;
  z-index: 1;
}
.swipe-cards-background::after {
  content: "";
  width: 270px;
  height: 362px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-6deg) translate(-50%, -50%);
  border-radius: 1rem;
  z-index: 1;
}
.info-title {
  padding: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
}
.info-subtitle {
  padding-bottom: 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}
.info-info {
  font-size: 0.8rem;
  line-height: 3.5;
  background-size: 120% 180%;
}
.info-text {
  padding: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
}
.info-hr {
  margin-bottom: 1rem;
  border: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.playlater-text {
  color: #721c24;
  background-color: #f8d7da;
  border-color: solid 1px #f291b8;
  text-align: start;
  margin: 5px;
  font-weight: 500;
  padding: 2rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}




.buy-more-card {
  /* // background-color: #fff;
  // border-left: solid 3px #205D88;
  // width: 20vw;
  // height: 10vw;
  // border-radius: 10px; */
  border-left: 10px solid #a661eb;
  margin: 1rem auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;

  .packinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4{
        margin: 0;
        text-align: center;

    }
    p{
        font-size: 2rem;
        margin: 10px;
        color:#6c757d!important
    }
  }
  .buy-more-chances-btn{
    color: #fff;
    background-color: #a661eb;
    /* border-color: #205d88; */
    font-size: 1.25rem;
    border-radius: 1.3rem;
    padding: 0.8rem;
    border: #fff;
    cursor: pointer;
  }
  .not-now{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    border:none;
    padding-top:10px
  }
}
@media only screen and (max-width: 600px) {
  .home h1 {
    font-size: 1.25rem; /* Smaller font size on very small screens */
  }
}

@media only screen and (min-width: 601px) {
  .home h1 {
    font-size: 1.5rem; /* Larger font size on bigger screens */
  }
}

/* Add other breakpoints as needed */
.responsive {
  max-width: 100%;
  height: auto;
}
  

