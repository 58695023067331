.button-box {
    display: flex;
    border: solid 1px #000000;
    border-radius: 10px;
    background-color: transparent;
    padding: 10px;
    justify-content: space-between;

    .Weekly-btn {
        color: black;
        background-color: transparent;
        border: none;
        padding: 5px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }


    .Monthly-btn {
        color: black;
        background-color: transparent;
        border: none;
        padding: 5px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }

    .PredictNWin-btn {
        color: black;
        background-color: transparent;
        border: none;
        font-size: 1rem;
        padding: 5px;
        font-weight: 600;
        cursor: pointer;
    }

    .active {
        background-color: #000000;
        color: #ffffff;
        border-radius: 10px;
        padding: 10px;
    }
}

.leader_box {
    overflow-y: scroll;

    .title {
        display: flex;
        background-color: #000000;
        margin-top: 10px;
        justify-content: space-between;
        border-radius: 10px;
        font-size: 1.7vh;
        padding: 0.5rem;

        .rank {
            color: #ffffff;
            padding: 10px
        }

        .user {
            color: #ffffff;
            padding: 10px;
        }

        .point {
            color: #ffffff;
            padding: 10px;
        }
    }

    .your-score {
        display: flex;
        background-color: rgb(238, 61, 90);
        border-radius: 10px;
        color: #ffffff;
        font-size: 2vh;
        margin-top: 10px;
        justify-content: space-between;

        .rank_data {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 2.4rem
        }

        .username_data {
            padding: 10px;
            display: flex;
            align-items: center;

            justify-content: center;

            img {
                padding: 10px
            }
        }

        .score_data {
            padding-right: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .weekly-data {
        background-color: #00658f;
        display: flex;
        border-radius: 10px;
        margin-top: 5px;
        color: #ffffff;
        font-size: 2vh;
        justify-content: space-between;

        .rank_data {
            padding: 10px;
            display: flex;
            padding-left: 2.4rem;
            align-items: center;
            justify-content: center;
        }

        .username_data {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                padding: 10px
            }
        }

        .score_data {
            padding-right: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .monthly-data {
        background-color: #00658f;
        display: flex;
        border-radius: 10px;
        margin-top: 5px;
        color: #ffffff;
        justify-content: space-between;
        font-size: 2vh;

        .rank_data {
            padding: 10px;
            display: flex;
            padding-left: 2.4rem;
            align-items: center;
            justify-content: center;
        }

        .username_data {
            padding: 10px;
            display: flex;
            align-items: center;

            justify-content: center;

            img {
                padding: 10px
            }
        }

        .score_data {
            padding-right: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .Predict_data {
        display: flex;
        font-size: 2vh;
        flex-direction: column;
        justify-content: space-between;

        .your-score {
            display: flex;
            align-items: center;
            background-color: rgb(238, 61, 90);
            border-radius: 10px;
            font-size: 2vh;
            color: #ffffff;

            .rank_data {
                padding: 10px;
                display: flex;
                padding-left: 1.4rem;
                align-items: center;
                justify-content: center;
            }

            .phone_no {
                padding: 10px;
                display: flex;
                align-items: center;
                margin-right: 26px;
                justify-content: center;
            }

            .attempt_question {
                padding: 10px;
                display: flex;
                margin-right: 5rem;
                align-items: center;
                justify-content: center;
            }

            .correct_ans {
                padding-right: 1rem;
                display: flex;
                margin-right: 4.9rem;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .predictNWin_list_Data {
        background-color: #00658f;
        display: flex;
        border-radius: 10px;
        margin-top: 5px;
        font-weight: 600;
        justify-content: space-between;
        font-size: 2vh;

        .rank_data {
            padding-left: 1.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .phone_no {
            padding: 10px;
            display: flex;
            align-items: center;
            margin-right: 26px;
            justify-content: center;
        }

        .attempt_question {
            padding: 10px;
            display: flex;
            margin-right: 5rem;
            align-items: center;
            justify-content: center;
        }

        .correct_ans {
            padding-right: 1rem;
            display: flex;
            margin-right: 4.2rem;
            align-items: center;
            justify-content: center;
        }
    }
}
.arabic-lang-leader{
    direction: rtl;
  }