:host {
    width     : 100%;
    height    : 100%;
    overflow  : hidden;
    position  : absolute;
    left      : 0;
    top       : 0;
   // background: #f9b970;
    background-color: #FDCB2E;
    /* fallback for old browsers */
    //background: -webkit-linear-gradient(to bottom, #f9b970, #f54048);
    /* Chrome 10-25, Safari 5.1-6 */
   // background: linear-gradient(to bottom, #f9b970, #f54048);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.terms-container {
    position: fixed;
    top     : 4rem;
    max-width: 500px;
    height  : 95%;
    width   :100%;
    padding : 1rem;
    overflow: scroll;
}

.terms-container-lang {
  //  position: fixed;
    top     : 0rem;
   max-width: 500px;
    height  : 100%;
    width   :100%;
    padding : 1rem;
}

.card-term {
    width        : 92%;
    height       : 92%;
    background-color: #ffffff;
    border       : none;
    padding: 1rem;
    border-radius: 1rem;
    overflow     : auto;
}
.card-lang {
    width        : 100%;
    height       : 92%;
    background-color: #ffffff;
    border       : none;
    padding: 1rem;
   
    margin-top: 3rem;
    border-radius: 1rem;
    overflow     : auto;
}

.card-lang .card-title {
    text-align : center;
    font-weight: 600;
}
.card-term .card-title {
    text-align : center;
    font-weight: 600;
}

ol {
    counter-reset: item;
}

.li-items {
    display: block;
}

.li-items:before {
    content : counters(item, ".") " ";
    counter-increment: item;
   font-weight: 500;
} 
.arabic-lang{
    margin: 5px;
    direction: rtl;
  }