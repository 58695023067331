.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-left: -15px;
  margin-right: -15px;

  .header_icon {
    span {
      margin: 0 10px;

      &.logo_main {
        margin-right: 30px;
      }

      img {
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
      }
    }

    &:first-child {
      span {
        margin-left: 0;
      }
    }

    &:last-child {
      span {
        margin-right: 0px;
      }
    }

    span {
      .cross {
        z-index: 1111111;
      }
    }
  }

  .header_icon_Swip span {
    margin: 0 10px;

    &.logo_main {
      cursor: pointer;
      /* // margin-left: 1rem; */
    }

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }

}

.hideNav {
  position: absolute;
  transform: translateX(200%);
  display: none;
}

.showNav {
  display: block;
  height: 100vh;
  position: absolute;
  z-index: 3000;
  transform: translateX(60%);
  transition: all 0.5s;
    -webkit-transition: all 0.25s;
  overflow: hidden;

  .close {
    position: absolute;
    top: 24px;
    border-radius: 50%;
    font-size: 28px;
    padding: 0;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
  }
}

.navbar {
  transition: 0.5s ease-in-out;
  z-index: 16;
  position: fixed;
  right: 32%;
  width: 295px;
}

@media (min-width: 760px) {
  .navbar {
    transition: 0.5s ease-in-out;
    z-index: 1;
    position: fixed;
    right: 19%;
    width: 295px;
  }

  .header .header_icon_Swip span {
    margin: 0 10px;

    &.logo_main {
      cursor: pointer;
      margin-left: 9rem;
    }

    img {
      display: inline-block;
    }
  }

}

@media (min-width: 900px) {
  .navbar {
    transition: 0.5s ease-in-out;
    z-index: 19;
    position: fixed;
    right: 42%;
    width: 295px;
  }

  .header .header_icon_Swip span {
    margin: 0 10px;

    &.logo_main {
      cursor: pointer;
      margin-left: 5rem;
    }

    img {
      display: inline-block;
    }
  }

}