:host {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  // background: #f9b970;
  background-color: #fdcb2e;
  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to bottom, #f9b970, #f54048);
  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to bottom, #f9b970, #f54048);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.main {
  .faqs-container {
    position: fixed;
    top: 4rem;
    max-width: 500px;
    height:93%;
    width: 100%;
    padding: 1rem;
    overflow: scroll;
  }

  .card-faqs {
  
    .list-data{
      ul li{
        list-style-type: disc;
      }
    }
  }

  .card-faqs .card-title {
    text-align: center;
    font-weight: 600;
  }
  .arabic-lang{
    margin: 5px;
    direction: rtl;
  }
}
