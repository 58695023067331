

/* CSS */
.btn {
    padding: 15px;
    margin: 1vh 0;
    width: 100%;
    border-radius: 10px;
    background-color: #ffcc00;
    border: solid 1px #ffffff;
    font-size: 1.4rem;
    color: #000000;
    font-weight: bolder;
    cursor: pointer;
    span{
        margin-left: 15px;
    }
}